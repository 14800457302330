import React from 'react'
import styles from './Main.module.css'
import Link from './Link/Link';
import appStore_en from '../../assets/img/app_store_en.svg'
import appStore_ru from '../../assets/img/app_store_ru.svg'
import googlePlay_en from '../../assets/img/google_play_en.png'
import googlePlay_ru from '../../assets/img/google_play_ru.png'
import { useTranslation } from 'react-i18next';

const Main = (props: any) => {
    const [t, i18n] = useTranslation();
    const isRu = i18n.language.includes('ru');
    return (
        <main className={styles.main}>
            <div className={styles.titles}>
                <h1>{t('Get smooth skin in the right way')}</h1>
                <h3>{t('Perfect skin in just 30 days')}</h3>
            </div>
            <div className={styles.links}>
                <Link img={isRu ? appStore_ru : appStore_en} link={'https://dk612.app.link/site_ios_redirect'} />
                <Link img={isRu ? googlePlay_ru : googlePlay_en} type="google" link={'https://dk612.app.link/site_android_link'} />
            </div>
        </main>
    )
}
export default Main;